body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
div.login{
  height: 500px;
  width: 500px;
}
table.login{
  /* background-image: url("../public/images/donkey_button.png");
  background-size: 450px 450px;
  background-position-x: center;
  background-position-y: center;
  background-blend-mode: lighten;
  background-repeat: no-repeat; */
  vertical-align: middle;
  height: 100%;
  width: 100%;
  border: 2px;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 30px;
  height: 20;
  margin: 15px auto;
}
html {    
  background: url("../public/images/donkey_button.png") no-repeat center fixed;     
  background-size:contain;
}
div.splashscreen{
  /* background-image: url("../public/images/donkey_button.png");
  background-size: 100% 100%;
  background-position-x: center;
  background-position-y: center;
  background-blend-mode: lighten;
  background-repeat: no-repeat; */
  /* border: 1px solid; */
  text-align: center;
  height: 600px;
  width: 600px;
  overflow-y:auto;
  scrollbar-width: none;
  margin: 0 auto;
}
div.scroll {
  height: 100%;  
  text-align: left;
  padding: 20px;
  overflow-y:auto;
  scrollbar-width: none;
  margin: 0 auto;
}
div.pokertable{
  border-start-start-radius: 10px 10px;
  border-start-end-radius: 10px 10px;
  border-end-end-radius: 10px 10px;
  border-end-start-radius: 10px 10px;
  border-color: brown;
  border: 2px solid;
  text-align: left;
  height: 450px;
  width: 100%;
  overflow-y:auto;
  /* scrollbar-width: none; */
  margin: 5px auto;
  background-color: rgb(5, 124, 57);
  opacity: 70%;
}
td.numeric{
  text-align: right;
  padding: 2px;
}
td.rightborder{
  text-align: center;
  border-right: 1px solid;
  border-bottom: 1px solid;
}
td.leftborder{
  text-align: center;
  border-right: 1px solid;
}
td.text{
  text-align: left;
  padding: 2px;
}

table.pokertable{
  /* background-image: url("../public/images/donkey_button.png");
  background-size: 800px 800px;
  background-position-x: center;
  background-position-y: center;
  background-blend-mode: lighten;
  background-repeat: no-repeat; */
  /* border: 1px solid; */
  text-align: center;
  height: 100px;
  width: 100%;
  overflow-y:auto;
  scrollbar-width: none;
  margin: 0 auto;

}
table.bb{
  background-image: url("../public/images/bb.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.sb{
  background-image: url("../public/images/sb.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.d{
  background-image: url("../public/images/d.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.utg{
  background-image: url("../public/images/utg.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.utg1{
  background-image: url("../public/images/utg1.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.utg2{
  background-image: url("../public/images/utg2.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.utg3{
  background-image: url("../public/images/utg3.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.utg4{
  background-image: url("../public/images/utg4.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.lj{
  background-image: url("../public/images/lj.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.hj{
  background-image: url("../public/images/hj.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.co{
  background-image: url("../public/images/co.png");
  image-rendering: crisp-edges;
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat1{
  background-image: url("../public/images/seat1.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat2{
  background-image: url("../public/images/seat2.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat3{
  background-image: url("../public/images/seat3.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat4{
  background-image: url("../public/images/seat4.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat5{
  background-image: url("../public/images/seat5.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat6{
  background-image: url("../public/images/seat6.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat7{
  background-image: url("../public/images/seat7.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat8{
  background-image: url("../public/images/seat8.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.seat9{
  background-image: url("../public/images/seat9.png");
  background-size: 25px 25px;
  background-position-x: -5px;
  background-position-y: -2px;
  background-blend-mode: inherit;
  background-repeat: no-repeat;
  z-index: 1;
}
table.pokertableseat{
  width: 90px;
  height: 110px;
  border: 2px;
  border-start-start-radius: 10px 10px;
  border-start-end-radius: 10px 10px;
  border-end-end-radius: 10px 10px;
  border-end-start-radius: 10px 10px;
  border-color: brown;
  border-style: solid;
  background-color: white;
  text-align: center;
  padding: 2px;
  font-size: 12pt;
  font-family: serif;
  margin: 0 auto;
}
table.pokertableseatbutton{
  width: 90px;
  height: 110px;
  border: 2px;
  border-start-start-radius: 10px 10px;
  border-start-end-radius: 10px 10px;
  border-end-end-radius: 10px 10px;
  border-end-start-radius: 10px 10px;
  border-color:red;
  background-color:gold;
  border-style: solid;
  text-align: center;
  padding: 2px;
  font-size: 12pt;
  font-family: serif;
  margin: 0 auto;
  z-index: 0;
}
table.pokertableopenseat{
  width: 90px;
  height: 110px;
  border: 2px;
  border-start-start-radius: 10px 10px;
  border-start-end-radius: 10px 10px;
  border-end-end-radius: 10px 10px;
  border-end-start-radius: 10px 10px;
  border-color: darkgray;
  background-color: gainsboro;
  border-style: solid;
  text-align: center;
  padding: 2px;
  font-size: 12pt;
  font-family: serif;
  margin: 0 auto;
}
table.centercontent{
  margin: 0 auto;
}
table.menu{
  width: 100%;
  border: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  margin: 5px auto;
}
td.items{
  border: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  background-color: white;
}
table.gamescheduler{
  width: 300px;
  border: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  margin: 0 auto;
}
table.pokertableboard{
  height: 75;
  border: 2px;
  background-color: white;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 5px;
  height: 20;
  opacity: .8;
  margin: 2px auto;
}
table.pokertableboarddesc{
  background-color: bisque;
  height: 75;
  border: 2px;
  border-start-start-radius: 10px 10px;
  border-start-end-radius: 10px 10px;
  border-end-end-radius: 10px 10px;
  border-end-start-radius: 10px 10px;
  /* writing-mode: vertical-rl; */
  border-style: solid;
  padding: 5px;
  height: 20;
  margin: 0 auto;
}
table.pokertablerabbit{
  height: 75;
  border: 2px;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  border-color:darkorange;
  padding: 5px;
  height: 20;
  margin: 0 auto;
}
table.tabledef{
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-color: green;
}
img.boardcard{
  height: 50px;
  width: 30px;
  background-color: white;
  border: 1px solid;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  padding: 5px;
}
img.rabbitcard{
  height: 50px;
  width: 30px;
  background-color: white;
  border: 1px solid;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  padding: 5px;
}
img.holecard{
  height: 30px;
  width: 20px;
  background-color: white;
  border: 1px solid;
  border-start-start-radius: 3px 3px;
  border-start-end-radius: 3px 3px;
  border-end-end-radius: 3px 3px;
  border-end-start-radius: 3px 3px;
  padding: 5px;
}
img.oddscard{
  height: 25px;
  width: 15px;
  background-color: white;
}
img.selectedoddscard{
  height: 25px;
  width: 15px;
  background-color: white;
  border: 1px solid;
  border-start-start-radius: 3px 3px;
  border-start-end-radius: 3px 3px;
  border-end-end-radius: 3px 3px;
  border-end-start-radius: 3px 3px;
  padding: 3px;
  margin: 2px;
}
img.actionscard{
  height: 15px;
  width: 12px;
  background-color: white;
  border: 1px solid;
  border-start-start-radius: 3px 3px;
  border-start-end-radius: 3px 3px;
  border-end-end-radius: 3px 3px;
  border-end-start-radius: 3px 3px;
  padding: 5px;
}
div.divcenter{
  text-align: center;
  align-content: center;
}
.centered{
  margin: 0 auto;
}

.rightaligned{
  margin-right: 0;
  margin-left: auto;
}

.leftaligned{
  margin-left: 0;
  margin-right: auto;
}
span.street{
  text-align: left;
  color: darkblue;
  font-weight: bold;
  font-size: 12pt;
  text-decoration: underline;
  font-family: serif;
}
span.params{
  text-align: left;
  background-color: white;
  color: darkblue;
  font-weight: bold;
  font-size: 12pt;
  font-family: serif;
}
span.resultsmsg{
  text-align: left;
  background-color: white;
  color: red;
  font-weight: bold;
  font-size: 18pt;
  font-family: serif;
}
span.substreet{
  text-align: left;
  color: darkblue;
  font-weight: bold;
  font-size: 12pt;
  font-family: serif;
}
span.actionstitle{
  text-align: left;
  color: white;
  font-size: 12pt;
  font-family: serif;
}
span.navpath{
  text-align: left;
  color: darkblue;
  background-color: white;
  font-weight: bold;
  font-size: 12pt;
  text-decoration: underline;
  font-family: serif;
}
span.actions{
  text-align: left;
  color: white;
  font-size: 12pt;
  font-family: serif;
  margin-left: 20px;
}
span.equitiestitle{
  text-align: left;
  color: white;
  font-size: 12pt;
  font-family: serif;
}
span.equities{
  text-align: left;
  color: white;
  font-size: 12pt;
  font-family: serif;
  margin-left: 20px;
}
td.seat{
  background-color: white;
  border-color: white;
  border: 2px;
  border-radius: 2%;
  border-style: solid;
  padding: 5px;
  margin: 0 auto;
}

td.profitpositive{
  background-color:lightgreen;
  border-color: green;
  border: 1px;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 1px;
  margin: 0 auto;
}

td.profitnegative{
  background-color:lightcoral;
  border-color: red;
  border: 1px;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 1px;
  margin: 1px auto;
}
tr.datatablegrey{
  background-color: lightgrey;
}
tr.datatablewhite{
  background-color: white;
}
p.tab {
   margin-left: 20px; 
}
a.signout{
  font-size: 7pt;
  color:blue;
  text-decoration: underline;
}
span.processing{
  width: 200px;
  height: 75px;
  border: 2px;
  border-start-start-radius: 20px 20px;
  border-start-end-radius: 20px 20px;
  border-end-end-radius: 20px 20px;
  border-end-start-radius: 20px 20px;
  border-color: brown;
  border-style: solid;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  color: white;
  padding: 2px;
  font-size: 20pt;
  font-family: serif;
  margin: 0 auto;
}
img.spinner{
  backface-visibility: visible;
  width: 150px;
  height: 150px;
  /* border: solid 2px;
  border-radius:50%; */
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  background-color: blue;
}
table.spinner{
  margin: 0 auto;
  z-index: 100;
  position: relative;
}
dialog.spinner{
  background-color: transparent;
  border: none;
  enable-background: false;
  z-index: 100;
}
img.playerimg{
  height: 35px;
  width: 35px;
  /* float: right; */
  overflow: hidden;
}
span.playername{
  writing-mode:vertical-lr;text-orientation: mixed;
}


/* ovelap images */
/* Image Container */
td.container{
  position: relative;
  width: 50px;
  height: 50px;
}
img.top{
  position: absolute;
  z-index: 1;
  /* width: 100%; */
  top: -10px;
  left: 2px;
}
img.bottom{
  position: absolute;
  z-index: 2;    /* Place this image in front of the img1 */
  /* width: 100%; */
  top: 15px;
  left: 15px;
}
img.check{
  position: relative;
  z-index: 2;    /* Place this image in front of the img1 */
  /* width: 100%; */
  top: 1px;
  left: 15px;
  width: 20px;
  height: 20px;
}

/*************** menu  **************/
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  /* display: inline-block; */
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  /* display: block; */
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  /* display: block; */
}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
th.tableheader{
  background-color: blue;
  color: white;
  font-weight: bold;
  font-size: 14pt;
}
td.underline{
  border-bottom: 2px solid blue;
  /* border-style: solid;
  border-width: 1px;
  border-color: blue; */
}
span.evpositive{
  background-color:lightgreen;
  border-color: green;
  border: 1px;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 1px;
  text-align: left;
  color: black;
  font-size: 11pt;
  font-family: serif;
  font-weight: bold;
  margin: 40px;
 }

span.evnegative{
  background-color:lightcoral;
  border-color: red;
  border: 1px;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 1px;
  text-align: left;
  color: black;
  font-size: 11pt;
  font-family: serif;
  margin: 40px;
  font-weight: bold;
}

span.evzero{
  border: 1px;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 1px;
  text-align: left;
  color: black;
  font-size: 11pt;
  font-family: serif;
  margin: 40px;
  font-weight: bold;
}

div.chart{
  height: 500px;
  width: 1000px;
}

@media (max-width:500px) {
  div.chart{
    height: 400px;
    width: 375px;
  }  
}

img.chartthumbnail{
  height: 30px;
  width: 50px;
  background-color: white;
  border: 1px solid white;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
}
button.oddscard{
  height: 30px;
  width: 20px;
  background-color: transparent;
  border: 1px solid;
  border-start-start-radius: 3px 3px;
  border-start-end-radius: 3px 3px;
  border-end-end-radius: 3px 3px;
  border-end-start-radius: 3px 3px;
  padding: 2px;
  margin: 3px;
}
img.bettingcard{
  height: 20px;
  width: 15px;
  background-color: transparent;
  border: 1px solid;
  border-start-start-radius: 3px 3px;
  border-start-end-radius: 3px 3px;
  border-end-end-radius: 3px 3px;
  border-end-start-radius: 3px 3px;
  padding: 2px;
  margin: 1px;
}
button.disabled{
  opacity: .65;
  border: 2px green;
 }
div.divider{
  height: 75;
  border: 2px;
  background-color: white;
  border-start-start-radius: 5px 5px;
  border-start-end-radius: 5px 5px;
  border-end-end-radius: 5px 5px;
  border-end-start-radius: 5px 5px;
  border-style: solid;
  padding: 5px;
  height: 20;
  opacity: .8;
  margin: 2px auto;
}










/* TREE VIEW  */

.basic.tree {
  list-style:none;
  margin: 0;
  padding: 2px;
}
.basic .tree-node,
.basic .tree-node-group {
  list-style: none;
  margin: 2px;
  padding: 0;
}

.basic .tree-branch-wrapper,
.basic .tree-node__leaf {
  outline: 1px green;
}

.basic .tree-node--focused {
  outline-color: rgb(77, 144, 254);
  outline-style: auto;
  outline-width: 2px;
  display: block;
  background-color: lightblue;
}

.basic .tree-node__branch {
  display: block;
}

.basic .tree-node {
  cursor: pointer;
  color: blue;
  font-weight: bold;
}

/* TREE VIEW  */